import React from 'react';
import {Input} from 'antd';

function EditableCell(props) {
  const fieldType = props.type || 'text';
  const initialValue = props.record[props.field];
  const rid = props.record.id;
  const [value, setValue] = React.useState(initialValue?.toString());
  const {setDirtyMap, latestChangeMap = {}} = props;
  const commonStyle = {width: props.width || 120};

  // the style is for UI only, so we don't check type
  // simply convert the initialValue to string and see if there're different
  /*
  const style =
    value === initialValue?.toString()
      ? {...commonStyle}
      : {
          ...commonStyle,
          border: '1px solid red',
        };
        */

  let style = {...commonStyle};

  if (value !== initialValue?.toString()) {
    style = {
      ...style,
      border: '1px solid red',
    };
  } else if (
    latestChangeMap[rid] &&
    latestChangeMap[rid][props.field] === initialValue
  ) {
    style = {
      ...style,
      border: '1px solid blue',
    };
  }

  return (
    <Input
      value={value}
      type={fieldType}
      onChange={(e) => {
        setValue(e.target.value);

        let nextValue = e.target.value;
        if (fieldType === 'number') {
          nextValue = parseFloat(e.target.value);
        }

        if (nextValue !== initialValue) {
          setDirtyMap((dirtyMap) => {
            return {
              ...dirtyMap,
              [rid]: {
                ...(dirtyMap[rid] || {}),
                [props.field]: nextValue,
              },
            };
          });
        } else {
          setDirtyMap((dirtyMap) => {
            const nextMap = {...dirtyMap};
            if (dirtyMap[rid]) {
              const dirtyObj = {...dirtyMap[rid]};
              delete dirtyObj[props.field];
              if (Object.keys(dirtyObj).length === 0) {
                delete nextMap[rid];
              } else {
                nextMap[rid] = dirtyObj;
              }
            }
            return nextMap;
          });
        }
      }}
      style={style}
    />
  );
}

export default EditableCell;
