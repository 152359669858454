import React from 'react';
import {message} from 'antd';
import EditableCell from '../../Components/EditableCell';
import GenericForm from '../../Generic/Form';
import * as JStorageActions from '../../Actions/JStorage';
import * as RakutenActions from '../../Actions/Rakuten';
import * as AppActions from '../../AppActions';

const collection = 'product';
const outletName = 'productDirtyMap';
const pageSizeOptions = [10, 30, 50];
const sortOptions = [
  {name: '價錢高到低', value: '-price'},
  {name: '價錢低到高', value: 'price'},
];

async function fetchMany(
  {sort, paging, keyword, startDate, endDate, itemStatus},
  onSuccess,
  onFailure,
) {
  console.log('fetchMany', startDate, endDate);
  try {
    AppActions.setLoading(true);
    const {total, results} = await RakutenActions.fetchCategoryList(
      {
        /*
        update_time_from: Math.floor(new Date(startDate).getTime() / 1000),
        update_time_to: Math.floor(new Date(endDate).getTime() / 1000),
        item_status: [itemStatus],
      */
      },
      paging,
    );
    onSuccess({total, results});
  } catch (ex) {
    console.warn('API error', ex);
    onFailure();
  } finally {
    AppActions.setLoading(false);
  }
}

async function updateMany({dirtyMap}, onSuccess) {
  const operations = [];
  for (const id in dirtyMap) {
    operations.push({
      method: 'update_one',
      payload: {
        query: {id},
        data: dirtyMap[id],
      },
    });
  }

  try {
    AppActions.setLoading(true);
    await JStorageActions.bulkWriteDocuments(collection, operations);
    await onSuccess();
  } catch (ex) {
  } finally {
    AppActions.setLoading(false);
  }
}

const makeColumns = ({setDirtyMap}) => [
  {
    title: 'id',
    key: 'shopCategoryId',
    dataIndex: 'shopCategoryId',
    width: 100,
    fixed: 'left',
  },
  {
    title: 'name',
    key: 'name',
    width: 500,
    render: (_, item) => {
      return <div>{item.categoryName?.zh_TW}</div>;
    },
  },
  {title: 'priority', key: 'priority', dataIndex: 'priority', width: 100},
];

const formSpec = {
  schema: {
    title: '',
    type: 'object',
    required: ['name', 'price'],
    properties: {
      item_id: {type: 'string', title: 'id', readOnly: true},
      item_name: {type: 'string', title: '商品名稱'},
      item_status: {type: 'string', title: '商品狀態'},
      category_id: {type: 'string', title: 'category_id'},
      description: {type: 'string', title: 'description'},
    },
  },
  uiSchema: {},
};

function Form(props) {
  const {instance} = props;

  return (
    <div>
      <GenericForm
        schema={formSpec.schema}
        uiSchema={formSpec.uiSchema}
        instance={instance}
        onSubmit={async (formData) => {
          try {
            AppActions.setLoading(true);
            await AppActions.delay(1000);
            console.log('formData', formData);
            message.success('成功!');
          } catch (ex) {
            message.error('API failure');
          } finally {
            AppActions.setLoading(false);
          }
        }}
      />
    </div>
  );
}

export {
  collection,
  outletName,
  pageSizeOptions,
  sortOptions,
  fetchMany,
  updateMany,
  makeColumns,
  Form,
};
